import { render, staticRenderFns } from "./EmptyContainer.vue?vue&type=template&id=12c9c9db&scoped=true&"
import script from "./EmptyContainer.vue?vue&type=script&lang=js&"
export * from "./EmptyContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12c9c9db",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})

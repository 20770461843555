<template>
  <div>
    <v-main>
      <div class="animated fadeIn">
        <router-view></router-view>
      </div>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'EmptyContainer',
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
